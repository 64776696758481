import React from 'react';
import './scss/Tag.scss';

class Tag extends React.Component {
  render() {
    const { title, id, group, isActive, setActiveTag} = this.props;
    return (
      <div className={isActive ? `tag tag--${group.toLowerCase()} is-active` : `tag tag--${group.toLowerCase()}`} onClick={() => setActiveTag(id)}>
        <span>{ title }</span>
      </div>
    )
  }
}

export default Tag;