import React from 'react';
import './scss/Project.scss';

class Project extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <li className="project">
        <a href={ data.link } target="_blank" rel="noopener noreferrer">{ data.title }</a>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path fillRule="evenodd" clipRule="evenodd" d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zM5.173 7.418l-.125-1.663 9.402-.707.707 9.402-1.663.125L13 8.01 6.387 15.7l-1.264-1.087 6.614-7.689-6.564.494z" fill="#00F"/>
        </svg>
      </li>
    )
  }
}

export default Project;