import React from 'react';
import Project from './Project';
import Tag from './Tag';
import './scss/App.scss';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      projects: {},
      tags: {},
      activeTag: null,
    };
  }

  componentDidMount() {
    // fetch projects data from API
    fetch('/projects.json')
      .then(data => data.json())
      .then(json => {
        this.setState({
          projects: {...json.data}
        });
      });

    fetch('/tags.json')
      .then(data => data.json())
      .then(json => {
        this.setState({
          tags: {...json.data}
        });
      });
  }

  setActiveTag = (key) => {
    const tags = {...this.state.tags};
    let { activeTag } = this.state;

    if (activeTag) {
      if (activeTag.id === tags[key].id) {
        activeTag = null;
      } else {
        activeTag = tags[key];
      }
    } else {
      activeTag = tags[key];
    }

    this.setState({ activeTag });
  }

  render() {
    const { projects, activeTag, tags } = this.state;
    let filteredProjects;

    if (activeTag) {
      filteredProjects = Object
        .keys(projects)
        .filter(key => {
          const projectTagIds = projects[key].tags.map(tag => tag.id);
          return projectTagIds.includes(activeTag.id);
        }) 
        .map(key => <Project key={key} data={projects[key]} />)
    } else {
      filteredProjects = Object
        .keys(projects)
        .map(key => <Project key={key} data={projects[key]} />) 
    }

    return (
      <div>
        <div className="container">
          <div className="side">
            <ul className="projects-list">
              {filteredProjects}
            </ul>
          </div>
          <div className="side">
            <ul className="tags-list">
              {Object
                .keys(tags)
                .map(key => <Tag key={key} title={tags[key].title} id={key} group={tags[key].group} isActive={activeTag && tags[key].id === activeTag.id} setActiveTag={this.setActiveTag} />)}
            </ul>
          </div>
        </div>
        <div className="details">
          <div className="contact">
            <span>Sebastian Brown</span>
            <span>Freelance developer & designer</span>
            <a href="mailto:contact@developer.sb">contact@developer.sb</a>
          </div>
          {activeTag && <div className="active-tag-info">
            <span>Active Tag</span>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9">
              <path d="M4.667.333L3.9 1.1l3.025 3.025H.333v1.083h6.592L3.9 8.234 4.667 9 9 4.667 4.667.333z" fill="#000"/>
            </svg>
            <span>{activeTag.group}</span>
            {activeTag.webAddress && (
              <>
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9">
                <path d="M4.667.333L3.9 1.1l3.025 3.025H.333v1.083h6.592L3.9 8.234 4.667 9 9 4.667 4.667.333z" fill="#000"/>
              </svg>
              <a href={activeTag.webAddress} target="_blank" rel="noopener noreferrer" className="active-tag-link">{activeTag.webAddress.replace('www.', '')}</a>
              </>
            )}
          </div>}
        </div>
      </div>
    );
  }
}

export default App;
